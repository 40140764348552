<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          rounded
          class="ma-2"
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Switch Center
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5" color="primary">
          Switch Center
        </v-card-title>
        <v-divider class="ma-1"></v-divider>
        <v-card-title>
          From:
          <br />{{ getUser.fulfillment }}
        </v-card-title>
        <v-divider class="ma-1"></v-divider>
        <v-card-title>
          To:
          <br />
          {{ center.locationName }}
        </v-card-title>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>

          <v-spacer></v-spacer>
          <v-btn color="pink" @click="SwitchCenter"> Switch </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["center"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    SwitchCenter() {
      console.log(this.center._id, this.getUser._id);
      let payload = {
        token: this.getToken,
        centerId: this.center._id,
        userId: this.getUser._id,
        route: this.$router,
      };
      this.$store.dispatch("switchCenter", payload);
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          small
          v-if="!FulfillmentCenter.isActive"
          color="pink"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Activate Center
        </v-btn>
        <v-btn small v-else color="info" dark v-bind="attrs" v-on="on">
          Deactivate Center
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Activate/Deactivate Center
        </v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="active"
            :label="FulfillmentCenter.locationName"
            color="info"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="pink" @click="ActivateOrDeactivateCenter()">
            Proceed
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["FulfillmentCenter"],
  data() {
    return {
      dialog: false,
      active: false,
    };
  },
  methods: {
    ActivateOrDeactivateCenter() {
      let payload = {
        token: this.getToken,
        active: this.active,
        centerId: this.FulfillmentCenter._id,
      };
      this.$store.dispatch("ActivateOrDeactivateCenter", payload);
      this.dialog = false;
    },
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.active = this.FulfillmentCenter.isActive;
      }
    },
  },
};
</script>
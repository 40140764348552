<template>
  <v-container>
    <v-data-table
      :headers="dessertHeaders"
      :items="AllFulfillmentCenter"
      item-key="_id"
      show-expand
      class="elevation-1"
      :single-expand="true"
      hide-default-footer
      @page-count="pageCount = $event"
      :loading="Loading"
      loading-text="Loading... Please wait"
      :items-per-page="20"
    >
      <template v-slot:item.createdAt="{ item }">
        <span>{{ DisplayTimeFormat(item.createdAt) }}</span>
      </template>
      <template v-slot:item.active="{ item }">
        <div class="ma-2">
          <ActivateOrDeactivateCenter :FulfillmentCenter="item" />
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-5">
          <div class="text-center">
            <h1 class="text-h4 font-weight-thin mb-4">Bike Service</h1>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Bike Service</td>
                  <td>{{ item.bikeService }}</td>
                </tr>
                <tr>
                  <td>Bike Km Charge</td>
                  <td>{{ item.bikeKmCharge }}</td>
                </tr>
                <tr>
                  <td>Bike Min Charge</td>
                  <td>{{ item.bikeMinCharge }}</td>
                </tr>
                <tr>
                  <td>Bike Max KM</td>
                  <td>{{ item.bikeMaxKM }}</td>
                </tr>
                <tr>
                  <td>Bike Reduce Charges After Km</td>
                  <td>{{ item.bikeReduceChargesAfterKm }}</td>
                </tr>
                <tr>
                  <td>Bike Reduce Fee After Km</td>
                  <td>{{ item.bikeReduceFeePerKm }}</td>
                </tr>
                <tr>
                  <td>Bike Reduce Fee After Km</td>
                  <td>{{ item.bikeReduceFeePerKm }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <h1 class="text-h4 font-weight-thin mb-4">Car Service</h1>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Car Service</td>
                  <td>{{ item.carService }}</td>
                </tr>
                <tr>
                  <td>Car Km Charge</td>
                  <td>{{ item.carKmCharge }}</td>
                </tr>
                <tr>
                  <td>Car Min Charge</td>
                  <td>{{ item.carMinCharge }}</td>
                </tr>
                <tr>
                  <td>Car Max KM</td>
                  <td>{{ item.carMaxKM }}</td>
                </tr>
                <tr>
                  <td>Car Reduce Charges After Km</td>
                  <td>{{ item.carReduceChargesAfterKm }}</td>
                </tr>
                <tr>
                  <td>Car Reduce Fee After Km</td>
                  <td>{{ item.carReduceFeePerKm }}</td>
                </tr>
                <tr>
                  <td>Car Reduce Fee After Km</td>
                  <td>{{ item.carReduceFeePerKm }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <h1 class="text-h4 font-weight-thin mb-4">Van Service</h1>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Van Service</td>
                  <td>{{ item.vanService }}</td>
                </tr>
                <tr>
                  <td>Van Km Charge</td>
                  <td>{{ item.vanKmCharge }}</td>
                </tr>
                <tr>
                  <td>Van Min Charge</td>
                  <td>{{ item.vanMinCharge }}</td>
                </tr>
                <tr>
                  <td>Van Max KM</td>
                  <td>{{ item.vanMaxKM }}</td>
                </tr>
                <tr>
                  <td>Van Reduce Charges After Km</td>
                  <td>{{ item.vanReduceChargesAfterKm }}</td>
                </tr>
                <tr>
                  <td>Van Reduce Fee After Km</td>
                  <td>{{ item.vanReduceFeePerKm }}</td>
                </tr>
                <tr>
                  <td>Van Reduce Fee After Km</td>
                  <td>{{ item.vanReduceFeePerKm }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <h1 class="text-h4 font-weight-thin mb-4">Truck Service</h1>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Truck Service</td>
                  <td>{{ item.truckService }}</td>
                </tr>
                <tr>
                  <td>Truck Km Charge</td>
                  <td>{{ item.truckKmCharge }}</td>
                </tr>
                <tr>
                  <td>Truck Min Charge</td>
                  <td>{{ item.truckMinCharge }}</td>
                </tr>
                <tr>
                  <td>Truck Max KM</td>
                  <td>{{ item.truckMaxKM }}</td>
                </tr>
                <tr>
                  <td>Truck Reduce Charges After Km</td>
                  <td>{{ item.truckReduceChargesAfterKm }}</td>
                </tr>
                <tr>
                  <td>Truck Reduce Fee After Km</td>
                  <td>{{ item.truckReduceFeePerKm }}</td>
                </tr>
                <tr>
                  <td>Truck Reduce Fee After Km</td>
                  <td>{{ item.truckReduceFeePerKm }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <h1 class="text-h4 font-weight-thin mb-4">Plane Service</h1>
          </div>
          <v-simple-table dark>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Plane Service</td>
                  <td>{{ item.planeService }}</td>
                </tr>
                <tr>
                  <td>Plane Km Charge</td>
                  <td>{{ item.planeKmCharge }}</td>
                </tr>
                <tr>
                  <td>Plane Min Charge</td>
                  <td>{{ item.planeMinCharge }}</td>
                </tr>
                <tr>
                  <td>Plane Max KM</td>
                  <td>{{ item.planeMaxKM }}</td>
                </tr>
                <tr>
                  <td>Plane Reduce Charges After Km</td>
                  <td>{{ item.planeReduceChargesAfterKm }}</td>
                </tr>
                <tr>
                  <td>Plane Reduce Fee After Km</td>
                  <td>{{ item.planeReduceFeePerKm }}</td>
                </tr>
                <tr>
                  <td>Plane Reduce Fee After Km</td>
                  <td>{{ item.planeReduceFeePerKm }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <div class="ma-2">
              <SwitchCenter :center="item" />
            </div>
            <div class="ma-2">
              <ViewFulfillmentCenterStaff :center="item" />
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
          {{ item.null }}
        </td>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="totalItems"></v-pagination>
    </div>
  </v-container>
</template>
<script>
import moment from "moment";
import SwitchCenter from "../components/Switch.vue";
import ActivateOrDeactivateCenter from "../components/ActivateOrDeactivateCenter.vue";
import ViewFulfillmentCenterStaff from "../components/ViewFulfillmentCenterStaff.vue";
export default {
  components: {
    SwitchCenter,
    ViewFulfillmentCenterStaff,
    ActivateOrDeactivateCenter,
  },
  data() {
    return {
      page: 1,
      Currpage: 1,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Address",
          align: "center",
          value: "locationName",
        },
        { text: "Active", align: "center", value: "active" },
        { text: "CreatedAT", align: "center", value: "createdAt" },
      ],
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
    Loading() {
      return this.$store.getters.TableLoading;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },

    AllFulfillmentCenter() {
      let FulfillmentCenter = this.$store.getters.AllFulfillmentCenter;
      return FulfillmentCenter;
    },
  },
  methods: {
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    getAllFulfillmentCenter() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("getAllFulfillmentCenter", payload);
    },
  },
  created() {
    this.getAllFulfillmentCenter();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllFulfillmentCenter", payload);
        return;
      }
    },
  },
};
</script>
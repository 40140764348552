<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="790">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          small
          rounded
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          View Staff
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          {{ center.locationName }}, Staffs
        </v-card-title>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in centerStaff" :key="item.name">
                <td>
                  {{ item.personalInfo.firstName }}
                  {{ item.personalInfo.lastName }}
                </td>
                <td>{{ item.personalInfo.phoneNumber }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="ActivitiesTotalItems"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["center"],
  data() {
    return {
      dialog: false,
      page: 1,
      Currpage: 1,
    };
  },
  computed: {
    ActivitiesTotalItems() {
      let total = this.$store.getters.staffWalletTransactionTotalItems;
      return Math.ceil(total / 20);
    },
    centerStaff() {
      return this.$store.getters.centerStaff;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
  },
  watch: {
    dialog: function (val) {
      if (val == true) {
        let payload = {
          token: this.getToken,
          page: 1,
          centerId: this.center._id,
        };
        this.$store.dispatch("getCenterStaffs", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
          centerId: this.center._id,
        };
        this.$store.dispatch("getCenterStaffs", payload);
        return;
      }
    },
  },
};
</script>